// stylelint-disable primer/no-override
// stylelint-disable primer/borders

// Border colors
.color-border-inverse   { border-color: var(--fgColor-onEmphasis, var(--color-fg-on-emphasis)) !important; }

.bg-gray-2,
.bg-gray-3 {
  background-color: var(--bgColor-neutral-muted, var(--color-neutral-muted)) !important;
}

.color-text-white {
  color: var(--color-scale-white) !important;
}

// TODO: Move somewhere. This is still needed for the mobile header or marketing pages like https://github.com/features/security
.border-white-fade  { border-color: rgba($static-color-white, 0.15) !important; }

/* Typography colors */

.lead {
  color: var(--fgColor-muted, var(--color-fg-muted));
}

.text-emphasized {
  color: var(--fgColor-default, var(--color-fg-default));
}

// Labels

.Label.Label--orange {
  color: var(--fgColor-severe, var(--color-severe-fg));
  border-color: var(--borderColor-severe-emphasis, var(--color-severe-emphasis));
}

.Label.Label--purple {
  color: var(--fgColor-done, var(--color-done-fg));
  border-color: var(--borderColor-done-emphasis, var(--color-done-emphasis));
}

.Label.Label--pink {
  color: var(--fgColor-sponsors, var(--color-sponsors-fg));
  border-color: var(--borderColor-sponsors-emphasis, var(--color-sponsors-emphasis));
}
